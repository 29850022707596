import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Previous Works',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id="showcase"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
              {/* <div 
                className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  ABOUT ME
                  </div>
                <h3 className="mt-0 mb-12 text-xs">
                  Irish Artist - Digital and Mixed media. I have a background in fashion. Previous projects include airbrush and wearable art. 
                  </h3>
                <p className="m-0 text-xs ">
                  My Vision for The Aurora project extends beyond the atmosphere. The Aurora Sky is not the limit! 
                  </p>
                  <p className="m-0 text-xs mt-8 ">  
                  My story of creating the collection is simple. I created a colourful gem collection centred around energy to brighten up your day and
                  I'm not just here to sell one collection,
                  I’m passionate about everything from art to fashion and I would love the freedom to create art ongoing in this space.
                  <p className="m-0 text-xs mt-8">  
                  I myself have hypermobile ehlers danlos syndrome, Which I personally don’t particularly like to highlight (although I'm happy to answer questions) . 
                  This means I have ultra flexible joints and skin. It often comes with pain but I consider myself one of the lucky ones with minimal restrictions and complications in my daily life.
                  I want to help others who are not so fortunate and live daily with debilitating pain and complications.
                  </p>
                  </p>   
                  <p className="m-0 text-xs mt-8 ">                             
                   It is largely an unrecognised and misunderstood condition and those affected often rely on charitable donations especially here in Ireland and that is why I chose to donate 15% from sales and 50% of royalties 
                   to charity for Ehlers-Danlos support.  
                  </p>    */}

 


                  {/* <p className="m-0 text-xs mt-8 mb-8 ">
                  Follow my <a href="https://twitter.com/TheAuroraArt" target="_blank">Twitter</a> or join my <a href="https://discord.gg/WEcfncHeRj" target="_blank">Discord</a> and stay up to date!
                  </p>
                  <p className="m-0 text-xs">
                  Please check out my pre-sale listings on <a href="https://exchange.art/collections/The%20Aurora%20Project%20" target="_blank">Exchange.art</a>
                  </p> */}
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/video-placeholder.png')}
                  alt="Features split 01"
                  width={200}
                  height={200} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div 
                className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                    SHOWCASE
                  </div>
                <h3 className=" mt-0 mb-12 text-xs">
                  The Aurora Gems!
                  </h3>
                <p className="m-0 text-xs">
                The Aurora Project gems are a unique collection of 200 colourful characters with vivid fantasy colours to brighten up the lives of those who hold them. 
                Every trait is hand drawn and every combination handpicked. These Gorgeous cuties come surrounded by beautiful fantasy night skies, colourful gem patterns and many different traits to brighten your mood.
                  </p> */}
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',   
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
              {/* <div 
                className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  MERCHANDISE
                  </div>
                <h3 className="mt-0 mb-12 text-xs">
                  Link to purchase some merchandise and brighten up your life!
                  </h3>
                  <ButtonGroup>
                <Button tag="a" color="light" wideMobile href="https://www.redbubble.com/people/electricemma/shop">
                  BUY NOW
                </Button>
              </ButtonGroup> */}
 
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
              {/* <div 
                
                  Please check out my pre-sale listings on <a href="https://exchange.art/collections/The%20Aurora%20Project%20" target="_blank">Exchange.art</a>
                  </p> */}
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 01"
                  width={200}
                  height={200} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;


